.Button {
    position: relative;

    border: none;
    cursor: pointer;
    outline: none;
    box-sizing: border-box;

    width: 100%;
    height: 100%;
    border-radius: 6px;
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-secondary);
    line-height:var(--line-height-secondary);
}

.Button::before {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    opacity: 0;
    background-color: red;
    border-radius: 6px;
    content: '';
}

.Button.Error::before {
    animation-duration: 1s; 
    animation-timing-function: linear; 
    animation-iteration-count: 2; 

    animation-name: alert-error;
}

@keyframes alert-error {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.Content {
    position: relative;
}

.NormalType {
    padding: 14px;
    background: var(--color-text-secondary);
    color: var(--background-primary);
}

.NormalType.IsMobile {
    padding: 16px;
    line-height: var(--line-height-mobile-button);
    font-size: var(--font-size-mobile-button);
}

.OutlineType {
    padding: 13px;
    color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
    background: var(--background-primary);
}

.OutlineType.IsMobile{
    padding: 15px;
    border-color: var(--color-hover-secondary);
    background: var(--color-hover-secondary);
    font-size: var(--font-size-mobile-button);
    line-height: var(--line-height-mobile-button);
}

.SizeXS.IsMobile {
    padding: 4px 12px;
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
    background: var(--background-primary);
}

.AccentType {
    padding: 16px;
    background: var(--background-accent);
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-mobile-button);
}

.AccentType.IsMobile{
    padding: 15px;
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-primary);
    line-height: var(--line-height-primary);
}

.IconType {
    background: none;
    transition: 0.3s filter ease-in, 0.3s transform ease-in;
}

.OutlineType:hover {
    background: var(--color-hover-secondary);
}

.IconType:hover {
    filter: brightness(80%);
    transform: scale(1.05);
}

.NormalType:hover {
    background: var(--color-hover-normal-button);
}

.SizeXS {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
    padding: 4px 12px;
}

