.ContentContainer {
    margin:0 85px;
}

.ContentContainer.IsMobile {
    margin: 24px 16px 0;
}

.TitleContainer {
    margin-bottom: 25px;
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-title);
    line-height: var(--line-height-title);
}

.ContentContainer.IsMobile .TitleContainer{
    text-align: center;
    margin-bottom: 40px;
    font-weight: var(--font-weight-xxl);
    font-size: var(--font-size-xxl);
    line-height: var(--line-height-xxl);
}


.ContentContainer.IsMobile .LocalityContainer{
    margin-bottom: 30px;
}

.DeliveriesContainer {
    margin-bottom: 25px;
    margin-top: 25px;
}

.ContentContainer.IsMobile .DeliveriesContainer {
    margin-bottom: 30px;
}

.DeliveryServiceName {
    margin-left: 14px;
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
}

.DeliveryServiceCostAndTime {
    margin-left: auto;
    font-weight: var(--font-weight-primary);
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
}

.ShippingInfoContainer {
    margin-bottom: 46px;
}

.ContentContainer.IsMobile .ShippingInfoContainer{
    margin: 0;
}

.DeliveryAddressContainer {
    margin-bottom: 22px;
}

.ContentContainer.IsMobile .DeliveryAddressContainer{
    margin-bottom: 25px;
}

.PromptContainer {
    display: flex;
    flex-flow: row wrap;
}

.ContentContainer.IsMobile .PromptContainer {
    margin-top: 25px;
}

.HintText {
    background: var(--color-hover-secondary);
    border-radius: 5px;
    color: var(--color-text-secondary);
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
    padding: 4px 12px;
    margin-right: 10px;
    cursor: pointer;
    margin-bottom: 10px;
}

.DeliveryServiceWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
}

.ChoosePvzWrapper {
    margin-bottom: 40px
}

.ChoosePvz {
    display: flex;
}

.ChoosePvzButton {
    width: 60%;
    margin-right: 25px;
}

.ContentContainer.IsMobile .ChoosePvzButton {
    width: 100%;
    margin: 0;
}

.ChoosePvzText {
    display: flex;
    align-items: center;
    width: 40%;
    font-size: 12px;
    font-weight: normal;
    line-height: 15px;
    color: var(--color-secondary);
    margin-left: 25px;
}
