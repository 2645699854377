.Overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;

    z-index: var(--modal-layer);
    background-color: rgba(0, 0, 0, 0.45);
}

.Container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.IsMobile .Container {
    align-items: flex-end;
}

.Content {
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%),
        0 6px 16px 0 rgb(0 0 0 / 8%), 
        0 9px 28px 8px rgb(0 0 0 / 5%);
}

.Hidden {
    display: none;
}
