.Wrapper {
    padding: 30px;
    background: var(--background-primary);
    border-radius: 6px;
}

.Wrapper.IsMobile {
    border-radius: 10px 10px 0 0;
}

.Title {
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
    text-align: center;

    margin-bottom: 35px;
}

.ButtonWrapper {
    padding: 5px;
}

.LeaveButton {
    margin-bottom: 12px;
}
