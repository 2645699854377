:root {
    --switch-state-button-height: 17px;
    --slider-header-title-margin-bottom: 32px;
    --slider-header-height: calc(
        var(--switch-state-button-height) +
        var(--line-height-primary) +
        var(--slider-header-title-margin-bottom)
    );
}

.Wrapper {
    touch-action: none;
}

.Container {
    z-index: var(--slider-layer);

    position: fixed;
    bottom: 0;
    transform: translateY(calc(100% - var(--slider-header-height)));
    width: 100%;
    background: white;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 
        0 6px 16px 0 rgb(0 0 0 / 8%), 
        0 9px 28px 8px rgb(0 0 0 / 5%);

    transition: transform 0.2s ease-in-out;
}

.Container[data-swiping-direction="Up"],
.Container[data-swiping-direction="Down"] {
    transition: unset;
}

.Opened .Container {
    transform: translateY(0);
}

.SwitchStateButtonWrapper {
    height: var(--switch-state-button-height);
}

.ProductTitle {
    display: flex;
    flex-direction: row;
    font-weight: var(--font-weight-primary);
    font-size: var(--font-size-primary);
    line-height: var(--line-height-primary);
    padding: 0 26px var(--slider-header-title-margin-bottom) 26px;
}

.ProductTitleButton {
    display: none;
    margin-left: auto;
}

.Opened .ProductTitleButton {
    display: block;
}

.Overlay {
    z-index: var(--overlay-layer);

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0;

    background-color: rgba(0, 0, 0, 0);
    
    transition: background-color 0.2s ease-in;
}

[data-swiping-direction="Up"] + .Overlay, 
.Opened .Overlay {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
}
