.Checked {
    background: var(--color-hover-secondary);
    border-radius: 6px;
}

.RadioLabel {
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px;
    cursor: pointer;
}

.RadioGroup {
    display: flex;
    flex-direction: column;
}

.RadioLabel:hover {
    padding: 12px;
    border: 2px solid var(--color-hover-secondary);
    border-radius: 6px;
}

.Checked:hover {
    background: var(--color-hover-secondary);
}

.RadioInput {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.Checkmark {
    height: 10px;
    width: 10px;
    border: 1px solid var(--color-text-secondary);
    border-radius: 100%;
}

.Checkmark::before,
.Checkmark::after {
    content: "";
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    transform: scale(0);
    transform-origin: center center;
    background: var(--color-text-secondary);
}

.RadioInput:hover+.Checkmark::before {
    opacity: .1;
    transform: scale(2);
}

.RadioInput:checked+.Checkmark::before {
    opacity: 1;
    transform: scale(.6);
}
