.Wrapper {
    margin: 30px 0;
}

.Wrapper.IsMobile {
    margin: 0 0 30px;
}

.SummaryContentWrapper {
    display: flex;
    flex-direction: column;
}

.Wrapper.IsMobile .SummaryContentWrapper {
    flex-direction: column-reverse;
}

.TotalWrapper {
    border-bottom: 1px solid var(--background-secondary);
}

.Wrapper.IsMobile .TotalWrapper{
    border: none;
}

.Total {
    margin: 0 30px 20px;
    display: flex;
    flex-direction: row;
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-title);
    line-height: var(--line-height-title);
}

.Wrapper.IsMobile .Total{
    margin: 15px 16px 0;
    font-size: var(--font-size-primary);
    line-height: var(--line-height-primary);
}

.TotalSum {
    margin-left: auto;
    font-weight: var(--font-weight-primary);
    font-size: var(--font-size-primary);
    line-height: var(--line-height-primary);
}

.TotalContent{
    margin-top: 20px;
}

.CostContainer {
    display: flex;
    flex-direction: column;
    margin: 20px 30px 0;
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
}

.Wrapper.IsMobile .CostContainer{
    margin: 17px 16px 15px;
}

.Wrapper.IsMobile .CostWrapper {
    border-top: 1px solid var(--background-secondary);
    border-bottom: 1px solid var(--background-secondary);
}

.Cost {
    display: flex;
    flex-direction: row;
}

.SaleContainer {
    margin:10px 0;
}

.Price {
    margin-left:auto;
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-weight-secondary);
    line-height: var(--line-height-secondary);
    max-height: 17px;
}

