.Wrapper {
    margin-bottom: 40px;
}

.Wrapper.IsMobile {
    margin: 0;
}

.Title {
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
    color: var(--color-secondary);
    margin-bottom: 10px;
}

.Item {
    display: flex;
    max-height: 24px;
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-xxl);
    margin-bottom: 20px;
    align-items: center;
}

.Item:last-of-type {
    margin: 0;
}

.Wrapper.IsMobile .Item{
    min-height: 25px;
    line-height: 14px;
    max-height: none;
}

.Label {
    margin-right: 10px;
}

.Wrapper.IsMobile .Label {
    margin-right: 25px;
}

.ItemSelected {
    color: var(--color-text-secondary);
}

.RightContent {
    margin-left: auto;
}

.Checkmark {
    max-width: 10px;
    max-height: 8px;
}

.CheckmarkContainer {
    margin-right: 8px;
}

.Selected {
    display: flex;
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
    padding: 0 8px 0 4px;
}
