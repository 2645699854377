.QuantityWrapper {
    display: flex;
}

.QuantityWrapper.IsMobile {
    margin-left: 3px;
}

.QuantityButtonMinus {
    margin-right: 8px;
}

.QuantityText {
    display: flex;
    min-width: 10px;
    justify-content: center;
    align-items: center;
}

.QuantityWrapper.IsMobile .QuantityText{
    font-weight: var(--font-weight-primary);
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
}

.QuantityButtonPlus {
    margin-left: 8px;
}
