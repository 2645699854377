.MobileContent {
    margin: 30px 10px 20px;
    background: var(--background-primary);
    box-shadow: 0 0 10px var(--background-secondary);
    border-radius: 4px;
}

.MobileCartProduct {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    background: var(--background-primary);
    box-shadow: 0 0 10px var(--background-secondary);
    border-radius: 10px 10px 0 0;
}

.ContinueButton {
    padding: 60px 16px 24px
}

.ContentWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    margin-bottom: var(--slider-header-height);
}

.Error {
    margin-top: 20px;
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
    color: var(--color-error);
    padding-left: 16px;
}
