.Container {
    margin: 0 85px 85px;
}

.Container.IsMobile {
    margin: 24px 16px 0;
}

.Title {
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-title);
    line-height: var(--line-height-title);
    margin-bottom: 25px;
}

.Container.IsMobile .Title{
    text-align: center;
    font-weight: var(--font-weight-xxl);
    font-size: var(--font-size-xxl);
    line-height: var(--line-height-xxl);
}

.PayMethodName {
    margin-left: 17px;
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
}
