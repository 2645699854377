.Wrapper {
    border-bottom: 1px solid var(--background-secondary);
    padding: 20px 85px;
}
.Wrapper.IsMobile {
    padding: 0 26px 14px;
    border: none;

}
.CartContainer{
    padding: 15px 0;
    border-bottom: 1px solid var(--background-secondary);
}

.CartContainer:last-of-type{
    border-bottom: none;
}

.Wrapper.IsMobile .CartContainer {
    padding: 10px 0;
}

.CartProduct {
    display: flex;
    flex-direction: row;
}

.Wrapper.IsMobile .CartProduct {
    justify-content: space-between;
}

.Wrapper.IsMobile .CartProduct:first-child {
    padding-top: calc(24px - var(--slider-header-title-margin-bottom));
}

.ImageProduct {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
}

.Wrapper.IsMobile .ImageProduct{
    height: 60px;
    width: 60px;
}

.InfoProduct {
    padding-left: 20px;
}

.Wrapper.IsMobile .InfoProduct{
    flex-grow: 1;
}

.InfoNameProduct {
    margin-bottom: 12px;
    font-weight: var(--font-weight-primary);
    font-size: var(--font-size-primary);
    line-height: var(--line-height-primary);
}

.Wrapper.IsMobile .InfoNameProduct{
    margin: 0;
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
}

.ProductParameters {
    display: flex;
    flex-direction: column;
}

.Wrapper.IsMobile .ProductParameters{
    width: 100%;
    margin-top: 17px;
}

.InfoParametersProduct {
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
}

.Wrapper.IsMobile .InfoParametersProduct{
    margin: 0;
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
}

.InfoQuantityProduct {
    display: flex;
    flex-direction: row;
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
}

.Wrapper.IsMobile .InfoQuantityProduct{
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
}

.InfoQuantityText {
    margin-right: 14px;
    display: flex;
    align-items: center;
}

.Wrapper.IsMobile .InfoQuantityText{
    margin: 0;
}

.PriceContainer {
    display: flex;
    margin-left: auto;
    font-weight: var(--font-weight-primary);
    font-size: var(--font-size-primary);
    line-height: var(--line-height-primary);
    min-width: 80px;
}

.Price {
    margin-left: auto;
}


.Wrapper.IsMobile .PriceContainer{
    min-width: 71px;
    margin: 0;
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
}


.OldPriceProduct {
    margin-top: 12px;
    text-decoration-line: line-through;
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
    color: var(--color-secondary);
}

.Wrapper.IsMobile .OldPriceProduct{
    margin-top: 0;
    font-size: var(--font-size-xs);
    line-height: var(--line-height-xs);
}

button {
    padding: 0;
}

.CartImg {
    max-width: 80px;
    max-height: 80px;
}

.Wrapper.IsMobile .CartImg{
    max-width: 60px;
    max-height: 60px;
}

.Feature {
    display: flex;
}


.Feature:last-of-type {
    margin-bottom: 10px;
}

.FeatureValue {
    font-weight: var(--font-weight-secondary);
}
