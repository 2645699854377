.SuggestionWrapper {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    z-index: 1;
    margin-top: 13px;
    box-shadow: 0 1px 10px var(--background-secondary);
    border-radius: 6px;
    background: var(--background-primary);
    padding: 12px 0 16px 14px;
}


.Item {
    padding-bottom: 14px;
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
    color: var(--color-secondary);
    cursor: pointer;
}

.Item:last-of-type {
    padding-bottom: 0;
}

.Selected {
    color: var(--color-primary);
}
