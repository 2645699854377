input {
    margin: 0;
}

.Container {
    width: 100%;
    max-height: 45px;

    position: relative;
}

.Container.IsMobile {
    max-height: 50px;
}

.Input {
    padding: 23px 0 5px 14px;
    outline: none;
    border: 1px solid var(--color-secondary);
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    max-height: 45px;

    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
}

.Container.IsMobile .Input {
    max-height: 50px;
    font-size: var(--font-size-mobile-button);
    line-height: var(--line-height-mobile-button);
    padding: 25px 0 5px 15px;
}

.Label {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    will-change: transform;

    color: var(--color-secondary);
    font-size: var(--font-size-secondary);
    font-weight: var(--font-weight-secondary);
    line-height: var(--line-height-secondary);
    transition: transform 0.3s linear;
    transform-origin: 0 50%;
    margin-left: 14px;
    cursor: text;
    user-select: none;
    transform: translateY(0) scale(1);
}

.Container.IsMobile .Label {
    font-size: var(--font-size-mobile-button);
    line-height: var(--line-height-mobile-button);
}

.Input:focus + .Label,
.Input:valid + .Label {
    transform: translateY(-10px) scale(0.86);

}

.ErrorInput {
    border-color: var(--color-error);
}

.ErrorLabel {
    color: var(--color-error);
}

.Input:hover {
     background: var(--color-hover-primary);
}

.Input:focus {
     background: var(--background-primary);
}
