
.DeliveryContainer {
    margin: 30px 30px 0;
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-title);
    line-height: var(--line-height-title);
}

.DeliveryContainer.IsMobile {
    margin: 0 16px 15px;
    font-size: var(--font-size-secondary);
    line-height: var(--line-height-secondary);
}

.Delivery, 
.Date {
    display: flex;
    flex-direction: row;
}

.Border {
    border-bottom: 1px dashed;
}

.Date {
    margin-top: 30px;
}

.DeliveryContainer.IsMobile .Date {
    margin-top: 10px;
}

.DeliveryAddress {
    margin-top: 10px;
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
    font-weight: var(--font-weight-normal);
}
