.DataContainer {
     margin: 40px 85px 40px 85px;
}

.DataContainer.IsMobile {
     margin: 24px 16px 0;
}

.Title {
     margin-bottom: 25px;
     font-weight: var(--font-weight-secondary);
     font-size: var(--font-size-title);
     line-height: var(--line-height-title);
}

.DataContainer.IsMobile .Title {
     margin-bottom: 40px;
     text-align: center;
     font-weight: var(--font-weight-xxl);
     font-size: var(--font-size-xxl);
     line-height: var(--line-height-xxl);
}


.PhoneNumberContainer {
     display: flex;
 }

.DataContainer.IsMobile .PhoneNumberContainer {
     flex-direction: column;
}

.PhoneNumberInputContainer {
     margin-right: 10px;
     width: 100%;
}

.DataContainer.IsMobile .PhoneNumberInputContainer {
     margin: 0;
}

.PhoneNumberButtonContainer {
     margin-left: 10px;
     width: 100%;
}

.DataContainer.IsMobile .PhoneNumberButtonContainer {
     margin: 15px 0 0 ;
}

.FullNameInputContainer {
     margin-top: 20px;
}

.DataContainer.IsMobile .FullNameInputContainer {
     margin-top: 30px;
}

.ConfirmatorWrapper {
     max-height: 45px;
}

.ConfirmedStatus {
     display: flex;
     color: #4796F7;

     height: 100%;
     align-items: center;
     justify-content: center;

     font-size: 14px;
     line-height: 17px;
}

.CheckIcon {
     height: 8px;
     width: 10px;

     margin-right: 8px;
}
