.ItemWrapper {
    width: 100%;

    padding-top: 10px;
    padding-bottom: 10px;
}

.Item {
    height: 25px;
    border-radius: 6px;

    background: radial-gradient(102% 40800% at 0% 52%, #F2F2F2 0%, #FAFAFA 65.11%, #F2F2F2 100%);
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
}

@keyframes skeleton-loading {
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}
