:root {
    --space-l: 16px;
    --space-xxl: 40px;

    --font-xl: 26px;

    --background-primary: #FFF;
    --background-secondary: #E5E5E5;
    --background-accent: #FADD56;
    --background-wrapper: #F9F9F9;

    --font-size-placeholder: 12px;
    --font-size-secondary: 14px;
    --font-size-mobile-button: 15px;
    --font-size-primary: 16px;
    --font-size-title: 18px;
    --font-size-xxl: 20px;
    --font-size-xs: 10px;

    --font-weight-primary: 600;
    --font-weight-secondary: 500;
    --font-weight-xxl: bold;
    --font-weight-normal: normal;

    --line-height-xs: 12px;
    --line-height-primary: 20px;
    --line-height-secondary: 17px;
    --line-height-placeholder: 15px;
    --line-height-mobile-button: 18px;
    --line-height-title: 22px;
    --line-height-xxl: 24px;

    --color-secondary: #939393;
    --color-hover-primary: #EBF4FE66;
    --color-hover-secondary: #EBF4FE;
    --color-text-secondary: #4796F7;
    --color-hover-normal-button: #4778F7;
    --color-primary: #000;
    --color-error: #E67070;

    --letter-spacing-mobile: -0.01em;

    /* z-index layers */
    --overlay-layer: 900;
    --slider-layer: 950;
    --modal-layer: 1000;
}

html {
  height: 100%;
  background-color: var(--background-wrapper);
}

body {
  height: 100%;
  overflow-y: auto;

  margin: 0;
}

body * {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
