.Container {
    background: white;
}

.LoadingPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;
}
