.Wrapper.IsMobile .DeliveryAndSummaryContainer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 30px;
}

.PrivacyPolicy {
    margin-top: 10px;
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
    color: var(--color-secondary);
}

.Wrapper.IsMobile .PrivacyPolicy {
    text-align: center;
}

.ButtonContainer {
    margin: 33px 30px 30px;
}

.Wrapper.IsMobile .Button {
    margin: 60px 16px 0;
}

.Border {
    border-bottom: 1px dashed;
}

.Error {
    margin-top: 10px;
    font-size: var(--font-size-placeholder);
    line-height: var(--line-height-placeholder);
    color: var(--color-error);
}
