.Wrapper {
    display: flex;
    flex-direction: column;
    background: var(--background-wrapper);
    height: 100%;
}

.HeaderWrapper{
    padding: 18px 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.BackContainer {
    height: 22px;
    width: 22px;
}

.Header {
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-title);
    line-height: var(--line-height-title);
    letter-spacing: var(--letter-spacing-mobile);
}

.CloseButtonContainer {
    max-height: 22px;
}

.PageNumber {
    margin-top: 12px;
    text-align: center;
    font-weight: var(--font-weight-secondary);
    font-size: var(--font-size-primary);
    line-height: var(--line-height-primary);
    letter-spacing: var(--letter-spacing-mobile);
    color: var(--color-secondary);
}

.MainContentWrapper {
    flex-grow: 1;
}