
.Container {
    display: flex;
    flex-direction: column;

    position: relative;
}

.ScrollTarget {
    position: absolute;
    top: -15px;
    height: 1px;
    width: 1px;
}

.Error {
     margin-top: 5px;
     font-size: var(--font-size-placeholder);
     line-height: var(--line-height-placeholder);
     color: var(--color-error);
     padding-left: 14px;
}
