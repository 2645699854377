.Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--background-wrapper);
    padding: 60px;
}

.Content {
    display: flex;
    flex-direction: column;
    min-width: 897px;
    max-width: 1131px;
    width: 100%;
}

.HeaderWrapper {
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;
}

.Header {
    font-size: var(--font-xl);
}


.LayoutContentWrapper {
    display: flex;
}

.MainContent {
    margin-right: max(20px, 4%);

    background: var(--background-primary);

    flex-basis: 58%;
    flex-grow: 1;
}

.Summary {
    align-self: flex-start;
    flex-basis: 32%;
    flex-grow: 1;

    position: sticky;
    top: var(--space-xxl);

    background: var(--background-primary);
}

@media (min-width: 1340px) {
    .MainContent {
        flex-basis: 55%;
        margin-right: 10%;
    }
    
    .Summary {
        flex-basis: 28%;
    }
}
